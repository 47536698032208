import React from 'react';

import terms from 'data/terms.json';
import { useTermsMarkdown } from 'hooks';

import {
	Category,
	Column,
	Container,
	Content,
	MarkdownContent,
	Section,
	Title,
	Updated
} from './TermsOfUse.style';

export function TermsOfUse() {
	const { binding, nonBinding } = useTermsMarkdown();

	return (
		<Container>
			<Content>
				<Title>{terms.title}</Title>
				<Updated>{terms.updated}</Updated>
				{binding.map((e, i) => (
					<Section key={`Binding-Content-${i}`}>
						<Column>
							<Category>{terms.binding}</Category>
							<MarkdownContent dangerouslySetInnerHTML={{ __html: e }} />
						</Column>
						{/* SHOULD UNCOMMENTED AFTER SHIRINA CONSTRUCTS NON_BINDING TEXT */}
						{/* {nonBinding[i] && (
							<Column>
								<Category>{terms.nonBinding}</Category>
								<MarkdownContent
									dangerouslySetInnerHTML={{
										__html: nonBinding[i]
									}}
								/>
							</Column>
						)} */}
					</Section>
				))}
			</Content>
		</Container>
	);
}
