import React from 'react';

import { TermsOfUse } from 'components/Terms';
import { BannerCta, SEO } from 'components/UI';
import content from 'data/terms.json';

export default function Terms() {
	return (
		<>
			<SEO />
			<TermsOfUse />
			<BannerCta {...content.cta} />
		</>
	);
}
